/*
  Ventura
*/
.form-control:focus {
  border-color: #c41230;
}

.bg-primary,
.badge-primary {
  background-color: #c41230 !important;
}

.text-primary,
.dropdown-menu>li>a.text-primary {
  color: #c41230 !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #ab0017 !important;
}

.btn-primary {
  background-color: #c41230;
  border: 1px solid #c41230;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
  background-color: #ab0017;
  border: 1px solid #ab0017;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
  background-color: #ab0017;
  border: 1px solid #ab0017;
}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle {
  background-color: #ab0017;
  border-color: #ab0017;
  color: #fff;
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #c41230;
  border-color: #c41230;
  color: #fff;
}

.btn-outline-primary {
  color: #c41230;
  border-color: #c41230;
}

.btn-outline-primary:hover {
  background-color: #c41230;
  border-color: #c41230;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #c41230;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: #c41230;
  border-color: #c41230;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  background-color: #c41230;
  border-color: #c41230;
}

.pagination>li>a,
.pagination>li>span {
  color: #c41230;
}

.page-link:hover {
  color: #c41230;
}

.page-item.active .page-link {
  background-color: #c41230;
  border-color: #c41230;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #c41230;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #c41230;
}

.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #c41230;
}

.login-wrapper .loginbox .login-left {
  background: linear-gradient(180deg, #c41230, #c41230);
}

.header {
  background: linear-gradient(-45deg, #c41230, #c41230);
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #c41230;
}

.nav-tabs.nav-tabs-solid>.active>a,
.nav-tabs.nav-tabs-solid>.active>a:hover,
.nav-tabs.nav-tabs-solid>.active>a:focus {
  background-color: #c41230;
  border-color: #c41230;
  color: #fff;
}

.nav-tabs.nav-tabs-solid>li>a.active,
.nav-tabs.nav-tabs-solid>li>a.active:hover,
.nav-tabs.nav-tabs-solid>li>a.active:focus {
  background-color: #c41230;
  border-color: #c41230;
  color: #fff;
}

.nav-tabs.nav-tabs-top>li>a.active,
.nav-tabs.nav-tabs-top>li>a.active:hover,
.nav-tabs.nav-tabs-top>li>a.active:focus {
  border-top-color: #c41230;
}

.nav-tabs.nav-tabs-bottom>li>a.active,
.nav-tabs.nav-tabs-bottom>li>a.active:hover,
.nav-tabs.nav-tabs-bottom>li>a.active:focus {
  border-bottom-color: #c41230;
}

.header .dropdown-menu>li>a:focus,
.header .dropdown-menu>li>a:hover {
  background-color: #c41230;
  color: #fff;
}

.header .dropdown-menu>li>a i {
  color: #c41230;
}

.header .user-menu .dropdown-menu>li>a i {
  color: #c41230;
}

.user-menu .dropdown-menu .dropdown-item:hover {
  color: #c41230;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #c41230 !important;
}

.mail-list>li.active>a {
  color: #c41230;
}

.error-box h1 {
  color: #c41230;
}

.sidebar-menu li a:hover {
  color: #c41230;
}

.sidebar-menu li.active a {
  background-color: #c41230;
}

.sidebar-menu>ul>li.active>a:hover {
  background-color: #ab0017;
}

.sidebar-menu ul ul a.active {
  color: #c41230;
  text-decoration: none;
}

.activity-feed .feed-item:after {
  background-color: #c41230;
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
  background-color: #c41230;
  border-color: #c41230;
}

.line {
  background-color: #c41230;
}

.avatar-sm {
  font-size: 30px;
  text-align: center;
  color: #000000;
}

.topnav-dropdown-header .clear-noti {
  color: #c41230;
  text-transform: none;
}

.bg-warning,
.badge-warning {
  color: #ffffff;
  background-color: #ffbc34 !important;
}

.no-sort {
  padding-right: 8px !important;
}

.page-wrapper>.content {
  padding: 0.875rem 1.875rem 0;
}

/*
  Xtended Asset Management
*/

a,
a:hover {
  color: inherit;
}

.no-link {
  cursor: pointer;
}

.icon-error {
  margin-right: 5px;
}

.spinner-border {
  vertical-align: middle !important;
  margin-top: -2px;
}

.modal-content {
  border: none;
}

.page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.view-loading {
  height: 100%;
}

.spinner-large {
  margin: 0px;
  height: 5rem;
  width: 5rem;
  color: #c41230;
}

.page-hide {
  opacity: 0;
}

.page-show {
  opacity: 100;
  display: flex !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.header .header-left .logo img {
  max-height: 30px;
}

.main-wrapper {
  height: initial;
  overflow: hidden;
}

.ie-wrapper {
  height: 100vh;
}

.logo {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.page-scroll {
  height: calc(100vh - 60px);
  overflow: auto;
}

.page-wrapper > .content {
  padding: 1.875rem 1.875rem 0 !important;
}

.card {
  border-radius: 0.3rem;
}

.card-title {
  border-bottom: 1px solid #e2e5e8;
}

.card-body {
  padding: 20px;
}

.activity-none {
  text-align: center;
}

.bg-purple,
.badge-purple {
  color: #ffffff;
}

.profile-title {
  padding-bottom: 22px !important;
}

.unconfirmed-hide {
  display: none;
}

.unconfirmed-show {
  display: initial !important;
  font-weight: bold;
  margin-left: 5px;
}

.form-token {
  background-color: #ffffff !important;
}

.btn-eye {
  font-family: initial;
}

.icon-eye {
  width: 22px;
  margin-top: 4px;
}

.notification-label {
  width: 130px;
}

.notification-checkbox {
  margin-right: 5px
}

.notification-modal-group {
  padding-top: 7px;
}

@media only screen and (max-width: 575.97px) {
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333% !important;
    flex: 0 0 8.333333% !important;
    max-width: 8.333333% !important;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667% !important;
    flex: 0 0 16.666667% !important;
    max-width: 16.666667% !important;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333% !important;
    flex: 0 0 83.333333% !important;
    max-width: 83.333333% !important;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667% !important;
    flex: 0 0 91.666667% !important;
    max-width: 91.666667% !important;
  }
}

@media only screen and (max-width: 767.97px) {
  .col-md-1 {
    -ms-flex: 0 0 8.333333% !important;
    flex: 0 0 8.333333% !important;
    max-width: 8.333333% !important;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667% !important;
    flex: 0 0 91.666667% !important;
    max-width: 91.666667% !important;
  }

  .login-wrapper .loginbox .login-left {
    display: flex !important;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    width: 100%;
  }

  .login-wrapper .loginbox {
    flex-direction: column;
  }
}

@media only screen and (max-width: 991.98px) {
  .sidebar {
    z-index: 1037;
  }
}

@media only screen and (min-width: 992px) {
  .modal-open.mini-sidebar .sidebar {
    z-index: 1039;
  }
}

/*
  AngularJS
*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/*
  DataTables
*/
table.dataTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

div.dataTables_filter label,
div.dataTables_length label {
  margin-bottom: 0px;
}

.dataTables_filter {
  display: inline-block;
}

.dataTables_length {
  display: inline-block;
  margin-left: 20px;
}

div.dataTables_wrapper div.dataTables_length select {
  margin-top: -1px;
}

.dataTables_info {
  float: left;
  padding-left: 20px;
  padding-top: 24px !important;
}

.dataTables_paginate {
  float: right;
  padding: 20px;
  padding-bottom: 18px;
  padding-top: 1px;
}

.table-title {
  margin-top: 2px;
  margin-bottom: 0px;
  float: left;
  padding: 20px;
}

.table-tools {
  float: right;
  padding: 20px;
}

.btn-table {
  display: inline-block;
  margin-left: 20px;
  padding: .31rem .5rem;
}

.table-scroll {
  width: 100%;
  overflow-x: auto;
  border-top: 1px solid #e2e5e8;
}

.table-flex {
  width: 100% !important;
}

@media only screen and (max-width: 570px) {
  .dataTables_filter {
    width: 100%;
  }

  .dataTables_length {
    margin-left: 0px;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .table-title {
    width: 100%;
    padding-bottom: 20px;
  }

  .table-tools {
    width: 100%;
    text-align: center;
    padding-top: 0px;
  }

  .dataTables_info {
    padding: 0px;
    float: none;
  }

  .dataTables_paginate {
    width: 100%;
    padding-top: 10px;
    float: none;
  }

  .pagination {
    justify-content: center !important;
  }
}